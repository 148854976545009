import React, { useCallback, useEffect, useState } from "react";

const SentenceDisplay = ({ data, onReload }) => {
  const [isAutoSpeak, setIsAutoSpeak] = useState(false);

  const speak = useCallback((textToSpeak) => {
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(textToSpeak);
      speechSynthesis.speak(utterance);
    }
  }, []);

  useEffect(() => {
    if (isAutoSpeak && data && data.text) {
      speak(data.text);
    }
  }, [data, onReload, speak, isAutoSpeak]);

  const toggleMute = () => {
    setIsAutoSpeak(!isAutoSpeak);
  };

  const shareOnX = (text, imageUrl) => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text
    )}&url=${encodeURIComponent(imageUrl)}`;
    window.open(twitterUrl, "_blank");
  };

  if (!data) return <div>Chargement...</div>;

  const { partOneSentence, partTwoSentence, text, count } = data;
  const backgroundColor = data.backgroundColor;

  const apiURL = process.env.REACT_APP_API_URL;

  return (
    <div className="sentence-container" style={{ backgroundColor }}>
      <div className="sentence-text">
        <h2>{text}</h2>
        <p className="counter">Phrase générée {count} fois</p>

        <button className="mute-button" onClick={toggleMute}>
          {!isAutoSpeak ? "🔇 auto OFF" : "🔊 auto ON"}{" "}
        </button>

        {!isAutoSpeak && (
          <button className="play-sentence" onClick={() => speak(text)}>
            Lire la phrase
          </button>
        )}

        <button className="refresh-button" onClick={onReload}>
          Charger une autre phrase
        </button>

        <a
          target="_blank"
          rel="noreferrer"
          href={apiURL + "/" + data.url}
          download={data.url}
        >
          <button className="download">Télécharger l'Image</button>
        </a>
      </div>
      <div className="sentence-corners">
        <div className="sentence-corner">
          <p className="sentence-title">Phrase 1</p>
          {partOneSentence.partOne} {partOneSentence.partTwo}
        </div>
        <div className="sentence-corner">
          <p className="sentence-title">Phrase 2</p>
          {partTwoSentence.partOne} {partTwoSentence.partTwo}
        </div>
      </div>
    </div>
  );
};

export default SentenceDisplay;
