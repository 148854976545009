import React, { useEffect, useState } from "react";
import SentenceDisplay from "./SentenceDisplay";

const App = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    loadData();
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "ArrowRight") {
        loadData();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  const loadData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/sentences/random`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Erreur:", error));
  };

  return (
    <div className="App">
      <header className="site-header">KAMOULBOX</header>
      <SentenceDisplay data={data} onReload={loadData} />
    </div>
  );
};

export default App;
